import cogoToast from 'cogo-toast';
import Swal from 'sweetalert2'
import Button from '@mui/material/Button';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './DownloadFile.css'

const baseURLgetFile = "https://upload-api.rbgzone.com.es/file/";

function DownloadFile() {

  const history = useHistory();
  const { fileId } = useParams();

  console.log(fileId)

  const handleDeleteClick = () => {
    if (!post) {
      cogoToast.error('The file was not found');
      return;
    }

    Swal.fire({
      title: 'Delete file',
      html: `<input type="text" id="token" class="swal2-input" placeholder="Token">`,
      confirmButtonText: 'Delete',
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const token = Swal.getPopup().querySelector('#token').value
        if (!token) {
          Swal.showValidationMessage(`Please enter a token to delete the file`)
        }
        return { token: token }
      }
    }).then((result) => {

      axios
        .delete(`${baseURLgetFile}${fileId}`, { params: { token: result.value.token } })
        .then(() => {
          Swal.fire({
            title: '<strong>File deleted</strong>',
            icon: 'success',
            html:
              'Your file was deleted successfully',
            showCloseButton: true,
            focusConfirm: true,
            confirmButtonText:
              'Close',
            confirmButtonAriaLabel: 'Close',
          }).then(res => { history.push("/"); })
        })
        .catch(res => {
          cogoToast.error('The token is not valid');
          console.error(res);
        });

    })

  };

  const handleDownloadClick = () => {
    if (!post) {
      cogoToast.error('The file was not found');
      return;
    }

    axios.get(baseURLgetFile + fileId + "/download").then((res) => {
        console.log(res.data)
        
        Swal.fire({
            title: '<strong>Download</strong>',
            icon: 'info',
            html:
              'Download your file by clicking the link:<br>' +
              `<a href="${res.data.uri}">Download</a><br>` +
              `Link expiration: ${new Date(res.data.expiration).toLocaleString()}`,
            showCloseButton: true,
            focusConfirm: true,
            confirmButtonText:
              'Close',
            confirmButtonAriaLabel: 'Close',
          })

      }).catch(err => { console.error(err); console.log("could not generate download link:", err.response.data.message) });    

  };

  const [post, setPost] = useState(null);

  useEffect(() => {
    axios.get(baseURLgetFile + fileId).then((response) => {
      console.log(response.data)
      setPost(response.data);
    }).catch(err => { cogoToast.error('The file was not found'); console.error(err); console.log("file not found:", err.response.data.message) });
  }, [fileId]);

  if (!post) return null;

  return (
    <div className='download-body'>
        <div style={{ fontSize: "25px", fontWeight: "700" }}>
          File found
        </div>

        <div className='download-file-card'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M 7 2 L 7 48 L 43 48 L 43 14.59375 L 42.71875 14.28125 L 30.71875 2.28125 L 30.40625 2 Z M 9 4 L 29 4 L 29 16 L 41 16 L 41 46 L 9 46 Z M 31 5.4375 L 39.5625 14 L 31 14 Z M 15 22 L 15 24 L 35 24 L 35 22 Z M 15 28 L 15 30 L 31 30 L 31 28 Z M 15 34 L 15 36 L 35 36 L 35 34 Z" />
          </svg>

          <div style={{ fontSize: "15px" }}>
            <p><strong>Filename:</strong> {post.filename}</p>
            <p><strong>Size:</strong> {post.sizeBytes} bytes</p>
            <p><strong>Expiration:</strong> {new Date(post.expiration).toLocaleString()}</p>
          </div>
        </div>

        <div className='download-buttons'>
          <Button variant="contained" onClick={handleDownloadClick}>Download</Button>
          <Button variant="contained" color="error" onClick={handleDeleteClick}>Delete</Button>
        </div>
    </div>
  );
}

export default DownloadFile;
