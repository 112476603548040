import cogoToast from 'cogo-toast';
import Swal from 'sweetalert2'
import { LinearProgressWithLabel } from '../simple/LinearProgressWithLabel';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { FilePicker } from '../filepicker/FilePicker';
import './UploadFile.css'

const baseURLgetStats = "https://upload-api.rbgzone.com.es/file/stats";
const baseURLupload = "https://upload-api.rbgzone.com.es/file";

function UploadFile() {

  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);

  const handleFileChange = (newFile) => {
    setFile(newFile);
  };

  const handleUploadClick = () => {
    if (!file) {
      cogoToast.error('You need to specify a file to upload');
      return;
    }

    cogoToast.loading('Uploading your file');

    // Uploading the file using the fetch API to the server
    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(baseURLupload, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress
      })
      .then((res) => {
        cogoToast.success('File uploaded successfully');
        console.log(res);

        axios.get(baseURLgetStats).then((response) => {
          console.log(response.data)
          setPost(response.data);
        }).catch(err => { console.error(err); });

        Swal.fire({
          title: '<strong>File uploaded</strong>',
          icon: 'success',
          html:
            'You can share it by using this link:<br>' +
            `
            <div style="padding: 15px;
            font-family: Courier, sans-serif;
            font-size: 1em;
            line-height: 1.3;
            color: #fff;
            background-color: #2c3e50;
            -webkit-border-radius: 6px 6px 6px 6px;
            -moz-border-radius: 6px 6px 6px 6px;
            border-radius: 6px 6px 6px 6px;
            margin-top: 10px;"><a style="color: white;" href="${window.location + res.data.id}">${window.location + res.data.id}</a></div>
            <br>
            And delete it by using this token:<br>            
            <div style="padding: 15px;
            font-family: Courier, sans-serif;
            font-size: 1em;
            line-height: 1.3;
            color: #fff;
            background-color: #2c3e50;
            -webkit-border-radius: 6px 6px 6px 6px;
            -moz-border-radius: 6px 6px 6px 6px;
            border-radius: 6px 6px 6px 6px;
            margin-top: 10px;">${res.data.token}</div>
            <br>` +
            `File expiration: ${new Date(res.data.expiration).toLocaleString()}`,
          showCloseButton: true,
          focusConfirm: true,
          confirmButtonText:
            'Close',
          confirmButtonAriaLabel: 'Close',
        }).then(res => { 
          setFile(null);
          setProgress(0);
        })

      })
      .catch((err) => {
        cogoToast.error('Could not upload the file');
        setProgress(0);
        console.log(err);
      });

  };

  const onUploadProgress = (e) => { 
    setProgress(e.progress*100);
    console.log(e);
  }

  const [post, setPost] = useState(null);

  useEffect(() => {
    axios.get(baseURLgetStats).then((response) => {
      console.log(response.data)
      setPost(response.data);
    }).catch(err => { console.error(err); });
  }, []);

  if (!post) return null;

  const formatter = Intl.NumberFormat("en-US", { minimumFractionDigits: 2 })
  const beautifySpace = (space) => formatter.format(Math.round((space / 1e6)*100)/100);

  return (
    <main className='upload-body'>
      
      <div className='upload-picker-area'>

        <FilePicker onChange={handleFileChange} file={file} progress={progress}/>

        {file && <div>
          <Button variant="contained" onClick={handleUploadClick}>Upload</Button>
        </div>}

      </div>

      <div className='upload-disk-area'>

        <div>
          {beautifySpace(post.totalFileSize)} out of {beautifySpace(post.maxTotalFileSize)} megabytes occupied
          <LinearProgressWithLabel value={(post.totalFileSize/post.maxTotalFileSize)*100} />
        </div>

      </div>
        
    </main>
  );
}

export default UploadFile;
