import Swal from 'sweetalert2'
import './HeaderMenu.css'

export function HeaderMenu() {

    const goToUpload = () => window.location.replace("/")

    const goToDownload = () => {

        Swal.fire({
        title: 'Download file',
        html: `<input type="text" id="fileid" class="swal2-input" placeholder="File ID">`,
        confirmButtonText: 'Download',
        showCancelButton: true,
        focusConfirm: false,
        preConfirm: () => {
            const fileId = Swal.getPopup().querySelector('#fileid').value
            if (!fileId) {
            Swal.showValidationMessage(`Please enter the ID of a file`)
            }
            return { fileId }
        }
        }).then((result) => {
        window.location.replace(`/${result.value.fileId}`)
        })

    };

    return (
        <header className='upload-header'>
        <span id="logo">FileUpload</span>
        <ul>
          <li onClick={goToUpload}>Upload</li>
          <li onClick={goToDownload}>Download</li>
          { /*<li>About</li>*/ }
        </ul>
      </header>
    )

}