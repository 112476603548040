import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import DownloadFile from './components/download/DownloadFile';
import UploadFile from './components/upload/UploadFile';
import { HeaderMenu } from './components/menu/HeaderMenu';
import './App.css';

function App() {

  return (
    <div className="App">
      <HeaderMenu />

      <main className="App-header">
          <Router>
            <Switch>
                <Route exact path='/:fileId' component={DownloadFile} />
                <Route component={UploadFile} />
            </Switch>
        </Router>
      </main>

      <footer className='upload-footer'>
        <span>Made with ❤️ by <a href="https://raulbalanza.me">Raúl Balanzá</a></span>
      </footer>

    </div>
  );

}

export default App;
