import { useRef } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import './FilePicker.css'

export function FilePicker({ onChange, file, progress }) {
    const inputRef = useRef(null);

    const handleClick = () => {
        inputRef.current.click();
    };

    const handleChange = (files) => {
        if (files && files.length > 0) {
            const newFile = files[0]
            onChange(newFile);
        }
    };

    return (
        <div className="fp-hidden-input-wrapper">
            <VisualPicker onClick={handleClick} onDrop={handleChange} file={file} progress={progress}/>
            <input
                type="file"
                className="fp-hidden-input"
                ref={inputRef}
                onChange={(ev) => handleChange(ev.target.files)}
            />
        </div>
    );
};

function VisualPicker({ onClick, onDrop, file, progress }) {

    const handleDragOver = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        ev.dataTransfer.dropEffect = "copy";
    };

    const handleDrop = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        onDrop(ev.dataTransfer.files);
    };

    return (

        <div 
            className="fp-main-area"
            onClick={onClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 16v-1.984q0-3.328 2.336-5.664t5.664-2.336q1.024 0 2.176 0.352 0.576-2.752 2.784-4.544t5.056-1.824q3.296 0 5.632 2.368t2.368 5.632q0 0.896-0.32 2.048 0.224-0.032 0.32-0.032 2.464 0 4.224 1.76t1.76 4.224v2.016q0 2.496-1.76 4.224t-4.224 1.76h-0.384q0.288-0.8 0.352-1.44 0.096-1.312-0.32-2.56t-1.408-2.208l-4-4q-1.76-1.792-4.256-1.792t-4.224 1.76l-4 4q-0.96 0.96-1.408 2.24t-0.32 2.592q0.032 0.576 0.256 1.248-2.72-0.608-4.512-2.784t-1.792-5.056zM10.016 22.208q-0.096-0.96 0.576-1.6l4-4q0.608-0.608 1.408-0.608 0.832 0 1.408 0.608l4 4q0.672 0.64 0.608 1.6-0.032 0.288-0.16 0.576-0.224 0.544-0.736 0.896t-1.12 0.32h-1.984v6.016q0 0.832-0.608 1.408t-1.408 0.576-1.408-0.576-0.576-1.408v-6.016h-2.016q-0.608 0-1.088-0.32t-0.768-0.896q-0.096-0.288-0.128-0.576z"></path>
            </svg>
            <div className='fp-upload-text'>
                <p>Choose a file to upload...<br/>{file && `${file.name} - ${file.type} - ${file.size} bytes`}</p>
                <p className='fp-upload-subtitle'>Maximum file size is 250MB</p>
                <p className='fp-upload-subtitle'>File expires in 15 minutes</p>
            </div>
            { progress > 0 && <LinearProgress className="fp-progress-bar" variant="determinate" value={progress} /> }
        </div>

    )

}